import Resource from './Resource'

const apiURL = `https://${process.env.VUE_APP_API_URL}`
let repositories = {}

export default {
  init: JWT => {
    repositories = {
      Articles: new Resource(JWT, { apiURL, resource: 'articles' }),
      Banners: new Resource(JWT, { apiURL, resource: 'banners' }),
      BannerSlides: new Resource(JWT, { apiURL, resource: 'banner_slides' }),
      Categories: new Resource(JWT, { apiURL, resource: 'categories' }),
      Configurations: new Resource(JWT, { apiURL, resource: 'product_configurations' }),
      Coupons: new Resource(JWT, { apiURL, resource: 'coupons' }),
      CustomFields: new Resource(JWT, { apiURL, resource: 'custom_fields' }),
      Enums: new Resource(JWT, { apiURL, resource: 'enums' }),
      Emails: new Resource(JWT, { apiURL, resource: 'emails' }),
      EmailTemplates: new Resource(JWT, { apiURL, resource: 'email_templates' }),
      Exports: new Resource(JWT, { apiURL, resource: 'exports' }),
      Files: new Resource(JWT, { apiURL, resource: 'files' }),
      Flights: new Resource(JWT, { apiURL, resource: 'flights' }),
      Locations: new Resource(JWT, { apiURL, resource: 'locations' }),
      Navigations: new Resource(JWT, { apiURL, resource: 'navigations' }),
      Orders: new Resource(JWT, { apiURL, resource: 'orders' }),
      Parameters: new Resource(JWT, { apiURL, resource: 'custom_parameters' }),
      Pdfs: new Resource(JWT, { apiURL, resource: 'pdfs' }),
      Products: new Resource(JWT, { apiURL, resource: 'products' }),
      ProductVariants: new Resource(JWT, { apiURL, resource: 'product_variants' }),
      Reviews: new Resource(JWT, { apiURL, resource: 'reviews' }),
      Users: new Resource(JWT, { apiURL, resource: 'users' }),
      Variables: new Resource(JWT, { apiURL, resource: 'custom_resources' }),
      Vouchers: new Resource(JWT, { apiURL, resource: 'vouchers' }),
      WebPages: new Resource(JWT, { apiURL, resource: 'web_pages' }),
      Sms: new Resource(JWT, { apiURL, resource: 'sms' }),
      SmsTemplates: new Resource(JWT, { apiURL, resource: 'sms_templates' }),
      Contacts: new Resource(JWT, { apiURL, resource: 'contacts' }),
    }
  },
  get: name => repositories[name],
}
