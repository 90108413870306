
import moment from 'moment'
import enumsEn from '@/locales/enum-en.json'
import enumsSk from '@/locales/enum-sk.json'


const slugify = txt => {
    let str = txt
    str = str.replace(/^\s+|\s+$/g, '')

    // Make the string lowercase
    str = str.toLowerCase()

    // Remove accents, swap ñ for n, etc
    const from = 'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽĹĽľĺáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;'
    const to = 'AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZLLllaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa______'
    for (let i = 0, l = from.length; i < l; i += 1) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    }

    // Remove invalid chars
    str = str.replace(/[^a-z0-9 _]/g, '')
        // Collapse whitespace and replace by _
        .replace(/\s+/g, '_')
        // Collapse dashes
        .replace(/-+/g, '_')

    return str
}

const slugifyURL = txt => {
    let str = txt
    str = str.replace(/^\s+|\s+$/g, '')

    // Make the string lowercase
    str = str.toLowerCase()

    // Remove accents, swap ñ for n, etc
    const from = 'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽĹĽľĺáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/-,:;'
    const to = 'AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZLLllaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------'
    for (let i = 0, l = from.length; i < l; i += 1) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    }

    // Remove invalid chars
    str = str.replace(/[^a-z0-9 -]/g, '')
        // Collapse whitespace and replace by _
        .replace(/\s+/g, '-')
        // Collapse dashes
        .replace(/-+/g, '-')

    return str
}

const generateRandomString = length => {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}

const showToast = (toast, toastComponent, title, text, variant) => {
    toast({
        component: toastComponent,
        position: 'top-right',
        props: {
            title,
            text,
            variant,
        },
    })
}

const getEnumTranslation = (name, key, lang = null) => {

    let enums = enumsEn
    if (lang) {
        if (lang === 'sk') {
            enums = enumsSk
        }
        if (lang === 'en') {
            enums = enumsEn
        }
    }

    if (enums[name]) {
        if (enums[name][key]) {
            return enums[name][key]
        }
    } else if (enums[key]) {
        return enums[key]
    }
    return null
}

const getAddUrl = (root) => {
    return `/add-${root}`;
}

const getDetailUrl = (root, id) => {
    return `/${root}s/${id}`;
}

const getEditUrl = (root, id) => {
    return `/${root}s/${id}/edit`;
}

const priceFormat = (num, decimal = 0) => {
    if (num) {
        let temp = parseFloat(num)
        if (decimal) {
            temp = temp.toFixed(decimal)
        }
        return temp.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' €'
    }
    if (num === 0) {
        return parseFloat(num).toFixed(decimal).replace('.', ',') + ' €'
    }
    return num
}

const formatDate = date => {
    if (date) {
        return moment(date).format('DD.MM.YYYY')
    }
    return null
}

const formatTime = date => {
    if (date) {
        return moment(date).format('HH:mm')
    }
    return null
}

const setLocalStorageCacheWithExpiry = (key, value, ttl) => { // ttl = ms
    const now = new Date()

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
}

const getLocalStorageCacheWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
    }
    return item.value
}

const clearLocalStorageCache = () => {
    for (let [key] of Object.entries(localStorage)) {
        if (key.startsWith(process.env.VUE_APP_PREFIX)) {
            localStorage.removeItem(key)
        }
    }
}

const buildParamString = (parameters) => {
    return parameters
        .filter((param) => param.customParameter.type === "VARIANT")
        .map((param) => `${param.customParameter.name}: ${param.value}`)
        .join(", ");
}

const getOrderTotal = (orderItems) => {
    const quantityAbleConfs = []
    let cartTotal = 0
    if (orderItems && orderItems.length) {
        for (const orderProduct of orderItems) {
            cartTotal += orderProduct.priceWithTax * orderProduct.quantity
            if (orderProduct.productConfigurations) {
                for (const configuration of orderProduct.productConfigurations) {
                    const index = quantityAbleConfs.findIndex(id => id === configuration.id)
                    if (configuration.quantityAble && index === -1) {
                        quantityAbleConfs.push(configuration.id)
                    } else if (configuration.quantityAble && index !== -1) {
                        continue
                    }
                    if (configuration.quantityAble) {
                        cartTotal += parseFloat(configuration.price)
                    } else {
                        cartTotal += parseFloat(configuration.price) * orderProduct.quantity
                    }
                }
            }
        }
    }
    return cartTotal
}

function cleanPayload(obj) {
    return Object.fromEntries(
        Object.entries(obj)
          // eslint-disable-next-line no-unused-vars
          .filter(([_, v]) => v !== '')
          .map(([k, v]) => [k, v === Object(v) ? cleanPayload(v) : v])
      );
}

function getUrlPreventBrowserCache(url) {
  return url+'?t='+(new Date()).getTime();
}

export default {
    slugify,
    slugifyURL,
    showToast,
    getEnumTranslation,
    generateRandomString,
    formatDate,
    formatTime,
    getAddUrl,
    getDetailUrl,
    getEditUrl,
    priceFormat,
    setLocalStorageCacheWithExpiry,
    getLocalStorageCacheWithExpiry,
    clearLocalStorageCache,
    buildParamString,
    getOrderTotal,
    cleanPayload,
  getUrlPreventBrowserCache
}
