<template>
  <div :class="{ active: navbarActive }"  id="main-navigation">
    <a href="javascript:void(0)" class="navbar-close" @click="closeNav">
       <bootstrap-icon
            icon="x-lg"
            size="1x"
          />
    </a>
    <router-link to="/" class="logo">
      <img src="@/assets/images/logo.svg" alt="logo" />
    </router-link>
    <div v-if="userData && userData.role === 'ROLE_ADMIN'" class="navigation-container">
      <!-- Main navigation links -->
      <nav id="navigation-links">
        <router-link to="/products" @click="closeNav">
          <bootstrap-icon
            icon="briefcase"
            size="1x"
          />
          {{ $t('navigations.products') }}
        </router-link>
        <router-link to="/categories" @click="closeNav">
          <bootstrap-icon
            icon="diagram-2"
            size="1x"
          />
          {{ $t('navigations.categories') }}
        </router-link>
        <router-link to="/parameters" @click="closeNav">
          <bootstrap-icon
            icon="sliders"
            size="1x"
          />
          {{ $t('navigations.parameters') }}
        </router-link>
        <router-link to="/configurations" @click="closeNav">
          <bootstrap-icon
            icon="gear"
            size="1x"
          />
          {{ $t('navigations.configurations') }}
        </router-link>
        <router-link to="/orders" @click="closeNav">
          <bootstrap-icon
            icon="bag"
            size="1x"
          />
          {{ $t('navigations.orders') }}
        </router-link>
        <!-- <router-link to="/baskets" @click="closeNav">
          <bootstrap-icon
            icon="bag-x"
            size="1x"
          />
          {{ $t('navigations.baskets') }}
        </router-link> -->
        <router-link to="/partners" @click="closeNav">
          <bootstrap-icon
            icon="people"
            size="1x"
          />
          {{ $t('navigations.partners') }}
        </router-link>
        <router-link to="/customers" @click="closeNav">
          <bootstrap-icon
            icon="person"
            size="1x"
          />
          {{ $t('navigations.customers') }}
        </router-link>
        <router-link to="/contacts" @click="closeNav">
          <bootstrap-icon
            icon="journals"
            size="1x"
          />
          {{ $t('navigations.contacts') }}
        </router-link>
        <router-link to="/locations" @click="closeNav">
          <bootstrap-icon
            icon="pin-map"
            size="1x"
          />
          {{ $t('navigations.locations') }}
        </router-link>
        <!-- <router-link to="/invoices">
          <bootstrap-icon
            icon="file-earmark-text"
            size="1x"
          />
          {{ $t('navigations.invoices') }}
        </router-link> -->
        <router-link to="/coupons" @click="closeNav">
          <bootstrap-icon
            icon="percent"
            size="1x"
          />
          {{ $t('navigations.coupons') }}
        </router-link>
        <router-link to="/reviews" @click="closeNav">
          <bootstrap-icon
            icon="star"
            size="1x"
          />
          {{ $t('navigations.reviews') }}
        </router-link>
        <router-link to="/emails" @click="closeNav">
          <BootstrapIcon
            icon="envelope-open"
            size="1x"
          />
          {{ $t('navigations.emails') }}
        </router-link>
        <router-link to="/email/templates" @click="closeNav">
          <BootstrapIcon
            icon="envelope"
            size="1x"
          />
          {{ $t('navigations.emailTemplates') }}
        </router-link>
        <router-link to="/sms" @click="closeNav">
          <BootstrapIcon
            icon="envelope-open"
            size="1x"
          />
          {{ $t('navigations.sms') }}
        </router-link>
        <router-link to="/sms/templates" @click="closeNav">
          <BootstrapIcon
            icon="envelope"
            size="1x"
          />
          {{ $t('navigations.smsTemplates') }}
        </router-link>
        <router-link to="/variables" @click="closeNav">
          <bootstrap-icon
            icon="grid"
            size="1x"
          />
          {{ $t('navigations.variables') }}
        </router-link>
        <div class="separator"></div>
        <router-link to="/vouchers" @click="closeNav">
          <bootstrap-icon
            icon="check2-square"
            size="1x"
          />
          {{ $t('navigations.vouchers') }}
        </router-link>
        <router-link to="/flights" @click="closeNav">
          <bootstrap-icon
            icon="briefcase"
            size="1x"
          />
          {{ $t('navigations.flights') }}
        </router-link>
        <div class="separator"></div>
        <router-link to="/pages" @click="closeNav">
          <bootstrap-icon
            icon="book"
            size="1x"
          />
          {{ $t('navigations.pages') }}
        </router-link>
        <router-link to="/articles" @click="closeNav">
          <bootstrap-icon
            icon="vector-pen"
            size="1x"
          />
          {{ $t('navigations.articles') }}
        </router-link>
        <router-link to="/banners" @click="closeNav">
          <bootstrap-icon
            icon="window"
            size="1x"
          />
          {{ $t('navigations.banners') }}
        </router-link>
        <router-link to="/navigations" @click="closeNav">
          <bootstrap-icon
            icon="send"
            size="1x"
          />
          {{ $t('navigations.navigation') }}
        </router-link>
        <router-link to="/exports" @click="closeNav">
          <bootstrap-icon
            icon="file-arrow-down"
            size="1x"
          />
          {{ $t('navigations.exports') }}
        </router-link>
        <router-link to="/logout">
          <bootstrap-icon
            icon="box-arrow-right"
            size="1x"
          />
          {{ $t('navigations.logout') }}
        </router-link>
      </nav>
    </div>
    <div v-else-if="userData && userData.role === 'ROLE_PARTNER'" class="navigation-container">
      <!-- Main navigation links -->
      <nav id="navigation-links">
        <router-link to="/vouchers" @click="closeNav" v-if="userData.customFields.find(cf => cf.name === 'redeem_voucher' && cf.value === 'true')">
          <bootstrap-icon
            icon="check2-square"
            size="1x"
          />
          {{ $t('navigations.partnerVouchers') }}
        </router-link>
        <router-link to="/flights" @click="closeNav" v-if="userData.customFields.find(cf => cf.name === 'discounted_flights' && cf.value === 'true')">
          <bootstrap-icon
            icon="briefcase"
            size="1x"
          />
          {{ $t('navigations.partnerFlights') }}
        </router-link>
        <router-link to="/records" @click="closeNav" v-if="userData.customFields.find(cf => cf.name === 'record' && cf.value === 'true')">
          <bootstrap-icon
            icon="film"
            size="1x"
          />
          {{ $t('navigations.partnerRecords') }}
        </router-link>
        <router-link to="/partner-invoices" @click="closeNav" v-if="userData.customFields.find(cf => cf.name === 'invoices' && cf.value === 'true')">
          <bootstrap-icon
            icon="file-text"
            size="1x"
          />
          {{ $t('navigations.partnerInvoices') }}
        </router-link>
        <router-link to="/logout">
          <bootstrap-icon
            icon="box-arrow-right"
            size="1x"
          />
          {{ $t('navigations.logout') }}
        </router-link>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  props: {
    navbarActive: { type: Boolean, default: false }
  },
  data () {
    return {
      userData: JSON.parse(localStorage.getItem('userData'))
    }
  },
  methods: {
    closeNav() {
      this.$emit('navToggle', false)
    },
    getActiveClass() {
      let className = ''
      if (this.navbarActive === true) {
        className = 'active'
      }
      return className
    },
  }
};
</script>
