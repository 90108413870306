import * as validators from "@vuelidate/validators";

// export const required = validators.helpers.withMessage('text', validators.required)

export const translator = {
    install: (app, options) => {
        app.config.globalProperties.$translateError = (key, altKey) => {
            const { messages, locale } = options
            const textKey = altKey ? altKey : key
            const message = messages[locale].validations[textKey] ? messages[locale].validations[textKey] : validators[key].$message
            return validators.helpers.withMessage(message, validators[key])
        }
    }
}