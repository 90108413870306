import { createStore } from 'vuex'

export default createStore({
  state: {
    appName: 'Letecké zážitky',
    apiUrl: `https://${process.env.VUE_APP_API_URL}`,
    defaultPerPage: 15
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
