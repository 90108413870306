import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { translator } from '@/libs/vuelidateMessages'
import { createHead } from '@vueuse/head'
import Bootstrap3 from 'bootstrap-vue-3'
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons';
//import vSelect from 'vue-select'
import store from './store'
import router from './router'
import App from './App.vue'
import FactoryRepository from '@/api/FactoryRepository'
import '@/libs/toastification'
import JWT from '@/auth/JWT'
import helperFunctions from '@/libs/helper'
//import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

const app = createApp(App)
app.use(Bootstrap3)
app.component('BootstrapIcon', BootstrapIcon);
app.use(router)
app.use(store)

const head = createHead()
app.use(head)

/* language files */
import textEn from '@/locales/text-en.json'
import textSk from '@/locales/text-sk.json'

const messages = {
  en: textEn,
  sk: textSk
}
const i18n = createI18n({
  locale: 'sk',
  fallbackLocale: 'en',
  messages
})
app.use(i18n)


const helper = {
  install() {
    app.helper = helperFunctions
    app.config.globalProperties.$helper = helperFunctions
  },
}
app.use(helper)


app.use(translator, {
  messages,
  locale: i18n.global.locale
})

// JWT
const Jwt = {
  install() {
    app.JWT = JWT
    app.config.globalProperties.$JWT = JWT
  },
}
app.use(Jwt)

// API Factory
FactoryRepository.init(JWT)
const api = {
  install() {
    app.Articles = FactoryRepository.get('Articles')
    app.config.globalProperties.$Articles = FactoryRepository.get('Articles')

    app.Banners = FactoryRepository.get('Banners')
    app.config.globalProperties.$Banners = FactoryRepository.get('Banners')

    app.BannerSlides = FactoryRepository.get('BannerSlides')
    app.config.globalProperties.$BannerSlides = FactoryRepository.get('BannerSlides')

    app.Categories = FactoryRepository.get('Categories')
    app.config.globalProperties.$Categories = FactoryRepository.get('Categories')

    app.Configurations = FactoryRepository.get('Configurations')
    app.config.globalProperties.$Configurations = FactoryRepository.get('Configurations')

    app.Coupons = FactoryRepository.get('Coupons')
    app.config.globalProperties.$Coupons = FactoryRepository.get('Coupons')

    app.CustomFields = FactoryRepository.get('CustomFields')
    app.config.globalProperties.$CustomFields = FactoryRepository.get('CustomFields')

    app.Enums = FactoryRepository.get('Enums')
    app.config.globalProperties.$Enums = FactoryRepository.get('Enums')

    app.Emails = FactoryRepository.get('Emails')
    app.config.globalProperties.$Emails = FactoryRepository.get('Emails')

    app.EmailTemplates = FactoryRepository.get('EmailTemplates')
    app.config.globalProperties.$EmailTemplates = FactoryRepository.get('EmailTemplates')

    app.Sms = FactoryRepository.get('Sms')
    app.config.globalProperties.$Sms = FactoryRepository.get('Sms')

    app.SmsTemplates = FactoryRepository.get('SmsTemplates')
    app.config.globalProperties.$SmsTemplates = FactoryRepository.get('SmsTemplates')

    app.Exports = FactoryRepository.get('Exports')
    app.config.globalProperties.$Exports = FactoryRepository.get('Exports')

    app.Files = FactoryRepository.get('Files')
    app.config.globalProperties.$Files = FactoryRepository.get('Files')

    app.Flights = FactoryRepository.get('Flights')
    app.config.globalProperties.$Flights = FactoryRepository.get('Flights')

    app.Locations = FactoryRepository.get('Locations')
    app.config.globalProperties.$Locations = FactoryRepository.get('Locations')

    app.Navigations = FactoryRepository.get('Navigations')
    app.config.globalProperties.$Navigations = FactoryRepository.get('Navigations')

    app.Orders = FactoryRepository.get('Orders')
    app.config.globalProperties.$Orders = FactoryRepository.get('Orders')

    app.Parameters = FactoryRepository.get('Parameters')
    app.config.globalProperties.$Parameters = FactoryRepository.get('Parameters')

    app.Pdfs = FactoryRepository.get('Pdfs')
    app.config.globalProperties.$Pdfs = FactoryRepository.get('Pdfs')

    app.Products = FactoryRepository.get('Products')
    app.config.globalProperties.$Products = FactoryRepository.get('Products')

    app.ProductVariants = FactoryRepository.get('ProductVariants')
    app.config.globalProperties.$ProductVariants = FactoryRepository.get('ProductVariants')

    app.Reviews = FactoryRepository.get('Reviews')
    app.config.globalProperties.$Reviews = FactoryRepository.get('Reviews')

    app.Users = FactoryRepository.get('Users')
    app.config.globalProperties.$Users = FactoryRepository.get('Users')

    app.Variables = FactoryRepository.get('Variables')
    app.config.globalProperties.$Variables = FactoryRepository.get('Variables')

    app.Vouchers = FactoryRepository.get('Vouchers')
    app.config.globalProperties.$Vouchers = FactoryRepository.get('Vouchers')

    app.WebPages = FactoryRepository.get('WebPages')
    app.config.globalProperties.$WebPages = FactoryRepository.get('WebPages')

    app.Contacts = FactoryRepository.get('Contacts')
    app.config.globalProperties.$Contacts = FactoryRepository.get('Contacts')
  },
}
app.use(api)
app.mount('#app')
