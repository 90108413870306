import JWT from './JWT'

export const isUserLoggedIn = () => localStorage.getItem('userData') && localStorage.getItem(JWT.config.storageTokenKeyName)

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'ROLE_ADMIN') return `/products`
  if (userRole === 'ROLE_PARTNER') return `/vouchers`
  if (userRole === 'ROLE_USER') return `/`
  if (userRole === 'ROLE_CLIENT') return `/`
  if (userRole) return '/logout'
  return { name: 'login' }
}