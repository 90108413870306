<template>
  <div class="layout-vertical">
    <NavBar :navbar-active="navbarActive" @navToggle="setNavVisible" />
    <div class="app-container">
        <NavBarTop @navToggle="setNavVisible" />
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/Navbar.vue' 
import NavBarTop from '@/components/NavbarTop.vue' 

export default { 
  components: {
    NavBar,
    NavBarTop
  }, 
  data() {
    return {
      navbarActive: false
    }
  },
  methods: {
    setNavVisible(data) {
      this.navbarActive = data
    }
  }
}
</script>