import { createRouter, createWebHistory } from 'vue-router'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

const routes = [
  // Home
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      pageTitle: 'navigations.home',
      layout: 'full',
    },
  },
  // Home End
  // Logout
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/Logout.vue'),
    meta: {
      pageTitle: 'navigations.logout',
      layout: 'full',
    },
  },
  // Logout End
  // Login
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      pageTitle: 'navigations.login',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  // Login End
  // Forgot Password
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      pageTitle: 'navigations.forgotPassword',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  // Forgot Password End
  // Orders
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/Admin/Orders/list.vue'),
    meta: {
      pageTitle: 'navigations.orders',
      breadcrumb: [
        {
          text: 'navigations.orders',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-order',
    name: 'AddNewOrder',
    component: () => import('@/views/Admin/Orders/add.vue'),
    meta: {
      pageTitle: 'order.new',
      breadcrumb: [
        {
          text: 'order.new',
          active: true,
        },
      ],
    },
  },
  {
    path: '/orders/:id/edit',
    name: 'EditOrder',
    component: () => import('@/views/Admin/Orders/edit.vue'),
    meta: {
      pageTitle: 'order.update',
      breadcrumb: [
        {
          text: 'order.update',
          active: true,
        },
      ],
    },
  },
  // Orders end
  // Baskets
  {
    path: '/baskets',
    name: 'baskets',
    component: () => import('@/views/Admin/Baskets/list.vue'),
    meta: {
      pageTitle: 'navigations.baskets',
      breadcrumb: [
        {
          text: 'navigations.baskets',
          active: true,
        },
      ],
    },
  },
  {
    path: '/baskets/:id/edit',
    name: 'EditBasket',
    component: () => import('@/views/Admin/Baskets/edit.vue'),
    meta: {
      pageTitle: 'order.basketUpdate',
      breadcrumb: [
        {
          text: 'order.basketUpdate',
          active: true,
        },
      ],
    },
  },
  // Baskets end
  // Pages
  {
    path: '/pages',
    name: 'pages',
    component: () => import('@/views/Admin/Pages/list.vue'),
    meta: {
      pageTitle: 'navigations.pages',
      breadcrumb: [
        {
          text: 'navigations.pages',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pages/:id/edit',
    name: 'EditPage',
    component: () => import('@/views/Admin/Pages/edit.vue'),
    meta: {
      pageTitle: 'page.update',
      breadcrumb: [
        {
          text: 'page.update',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-page',
    name: 'AddNewPage',
    component: () => import('@/views/Admin/Pages/add.vue'),
    meta: {
      pageTitle: 'page.new',
      breadcrumb: [
        {
          text: 'page.new',
          active: true,
        },
      ],
    },
  },
  // Pages End
  // Articles
  {
    path: '/articles',
    name: 'articles',
    component: () => import('@/views/Admin/Articles/list.vue'),
    meta: {
      pageTitle: 'navigations.articles',
      breadcrumb: [
        {
          text: 'navigations.articles',
          active: true,
        },
      ],
    },
  },
  {
    path: '/articles/:id/edit',
    name: 'EditArticle',
    component: () => import('@/views/Admin/Articles/edit.vue'),
    meta: {
      pageTitle: 'article.update',
      breadcrumb: [
        {
          text: 'article.update',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-article',
    name: 'AddNewArticle',
    component: () => import('@/views/Admin/Articles/add.vue'),
    meta: {
      pageTitle: 'article.new',
      breadcrumb: [
        {
          text: 'article.new',
          active: true,
        },
      ],
    },
  },
  // Articles End
  // Navigations
  {
    path: '/navigations',
    name: 'navigations',
    component: () => import('@/views/Admin/Navigations/list.vue'),
    meta: {
      pageTitle: 'navigations.navigation',
      breadcrumb: [
        {
          text: 'navigations.navigation',
          active: true,
        },
      ],
    },
  },
  {
    path: '/navigations/:id/edit',
    name: 'EditNavigation',
    component: () => import('@/views/Admin/Navigations/edit.vue'),
    meta: {
      pageTitle: 'navigation.update',
      breadcrumb: [
        {
          text: 'navigation.update',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-navigation',
    name: 'AddNewNavigation',
    component: () => import('@/views/Admin/Navigations/add.vue'),
    meta: {
      pageTitle: 'navigation.new',
      breadcrumb: [
        {
          text: 'navigation.new',
          active: true,
        },
      ],
    },
  },
  // Navigations End
  // Customers
  {
    path: '/customers',
    name: 'customers',
    component: () => import('@/views/Admin/Customers/list.vue'),
    meta: {
      pageTitle: 'navigations.customers',
      breadcrumb: [
        {
          text: 'navigations.customers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customers/:id',
    name: 'CustomerDetail',
    component: () => import('@/views/Admin/Customers/detail.vue'),
    meta: {
      pageTitle: 'customer.detail',
      breadcrumb: [
        {
          text: 'customer.detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-customer',
    name: 'AddNewCustomer',
    component: () => import('@/views/Admin/Customers/add.vue'),
    meta: {
      pageTitle: 'customer.new',
      breadcrumb: [
        {
          text: 'customer.new',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customers/:id/edit',
    name: 'EditCustomer',
    component: () => import('@/views/Admin/Customers/edit.vue'),
    meta: {
      pageTitle: 'customer.update',
      breadcrumb: [
        {
          text: 'customer.update',
          active: true,
        },
      ],
    },
  },
  // Customers End
  // Contacts
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('@/views/Admin/Contacts/list.vue'),
    meta: {
      pageTitle: 'navigations.contacts',
      breadcrumb: [
        {
          text: 'navigations.contacts',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-contact',
    name: 'AddNewContact',
    component: () => import('@/views/Admin/Contacts/add.vue'),
    meta: {
      pageTitle: 'contact.new',
      breadcrumb: [
        {
          text: 'contact.new',
          active: true,
        },
      ],
    },
  },
  {
    path: '/contacts/:id/edit',
    name: 'EditContact',
    component: () => import('@/views/Admin/Contacts/edit.vue'),
    meta: {
      pageTitle: 'contact.update',
      breadcrumb: [
        {
          text: 'contact.update',
          active: true,
        },
      ],
    },
  },
  // Contacts End
  // Banners
  {
    path: '/banners',
    name: 'banners',
    component: () => import('@/views/Admin/Banners/list.vue'),
    meta: {
      pageTitle: 'navigations.banners',
      breadcrumb: [
        {
          text: 'navigations.banners',
          active: true,
        },
      ],
    },
  },
  {
    path: '/banners/:id/edit',
    name: 'EditBanner',
    component: () => import('@/views/Admin/Banners/edit.vue'),
    meta: {
      pageTitle: 'banner.update',
      breadcrumb: [
        {
          text: 'banner.update',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-banner',
    name: 'AddNewBanner',
    component: () => import('@/views/Admin/Banners/add.vue'),
    meta: {
      pageTitle: 'banner.new',
      breadcrumb: [
        {
          text: 'banner.new',
          active: true,
        },
      ],
    },
  },
  {
    path: '/banner-slides/:id/edit',
    name: 'EditBannerSlide',
    component: () => import('@/views/Admin/Banners/editSlide.vue'),
    meta: {
      pageTitle: 'slide.update',
      breadcrumb: [
        {
          text: 'slide.update',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-banner-slide',
    name: 'AddNewBannerSlide',
    component: () => import('@/views/Admin/Banners/addSlide.vue'),
    meta: {
      pageTitle: 'slide.new',
      breadcrumb: [
        {
          text: 'slide.new',
          active: true,
        },
      ],
    },
  },
  // Banners End

  // Exports
  {
    path: '/exports',
    name: 'exports',
    component: () => import('@/views/Admin/Exports/index.vue'),
    meta: {
      pageTitle: 'navigations.exports',
      breadcrumb: [
        {
          text: 'navigations.exports',
          active: true,
        },
      ],
    },
  },
  // Exports End

  // Products
  {
    path: '/products',
    name: 'products',
    component: () => import('@/views/Admin/Products/list.vue'),
    meta: {
      pageTitle: 'navigations.products',
      breadcrumb: [
        {
          text: 'navigations.products',
          active: true,
        },
      ],
    },
  },
  {
    path: '/products/:id/edit',
    name: 'EditProduct',
    component: () => import('@/views/Admin/Products/edit.vue'),
    meta: {
      pageTitle: 'product.update',
      breadcrumb: [
        {
          text: 'product.update',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-product',
    name: 'AddNewProduct',
    component: () => import('@/views/Admin/Products/add.vue'),
    meta: {
      pageTitle: 'product.new',
      breadcrumb: [
        {
          text: 'product.new',
          active: true,
        },
      ],
    },
  },
  // Products End

  // Categories
  {
    path: '/categories',
    name: 'categories',
    component: () => import('@/views/Admin/Categories/list.vue'),
    meta: {
      pageTitle: 'navigations.categories',
      breadcrumb: [
        {
          text: 'navigations.categories',
          active: true,
        },
      ],
    },
  },
  {
    path: '/categories/:id/edit',
    name: 'EditCategory',
    component: () => import('@/views/Admin/Categories/edit.vue'),
    meta: {
      pageTitle: 'category.update',
      breadcrumb: [
        {
          text: 'category.update',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-category',
    name: 'AddNewCategory',
    component: () => import('@/views/Admin/Categories/add.vue'),
    meta: {
      pageTitle: 'category.new',
      breadcrumb: [
        {
          text: 'category.new',
          active: true,
        },
      ],
    },
  },
  // Categories End

  // Parameters
  {
    path: '/parameters',
    name: 'parameters',
    component: () => import('@/views/Admin/Parameters/list.vue'),
    meta: {
      pageTitle: 'navigations.parameters',
      breadcrumb: [
        {
          text: 'navigations.parameters',
          active: true,
        },
      ],
    },
  },
  {
    path: '/parameters/:id/edit',
    name: 'EditParameter',
    component: () => import('@/views/Admin/Parameters/edit.vue'),
    meta: {
      pageTitle: 'parameter.update',
      breadcrumb: [
        {
          text: 'parameter.update',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-parameter',
    name: 'AddNewParameter',
    component: () => import('@/views/Admin/Parameters/add.vue'),
    meta: {
      pageTitle: 'parameter.new',
      breadcrumb: [
        {
          text: 'parameter.new',
          active: true,
        },
      ],
    },
  },
  // Parameters End

  // Configurations
  {
    path: '/configurations',
    name: 'configurations',
    component: () => import('@/views/Admin/Configurations/list.vue'),
    meta: {
      pageTitle: 'navigations.configurations',
      breadcrumb: [
        {
          text: 'navigations.configurations',
          active: true,
        },
      ],
    },
  },
  {
    path: '/configurations/:id/edit',
    name: 'EditConfiguration',
    component: () => import('@/views/Admin/Configurations/edit.vue'),
    meta: {
      pageTitle: 'configuration.update',
      breadcrumb: [
        {
          text: 'configuration.update',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-configuration',
    name: 'AddNewConfiguration',
    component: () => import('@/views/Admin/Configurations/add.vue'),
    meta: {
      pageTitle: 'configuration.new',
      breadcrumb: [
        {
          text: 'configuration.new',
          active: true,
        },
      ],
    },
  },
  // Configurations End

  // Partners
  {
    path: '/partners',
    name: 'partners',
    component: () => import('@/views/Admin/Partners/list.vue'),
    meta: {
      pageTitle: 'navigations.partners',
      breadcrumb: [
        {
          text: 'navigations.partners',
          active: true,
        },
      ],
    },
  },
  {
    path: '/partners/:id/edit',
    name: 'EditPartner',
    component: () => import('@/views/Admin/Partners/edit.vue'),
    meta: {
      pageTitle: 'partner.update',
      breadcrumb: [
        {
          text: 'partner.update',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-partner',
    name: 'AddNewPartner',
    component: () => import('@/views/Admin/Partners/add.vue'),
    meta: {
      pageTitle: 'partner.new',
      breadcrumb: [
        {
          text: 'partner.new',
          active: true,
        },
      ],
    },
  },
  // Partners End

  // Coupons Start
  {
    path: '/coupons',
    name: 'coupons',
    component: () => import('@/views/Admin/Coupons/list.vue'),
    meta: {
      pageTitle: 'navigations.coupons',
      breadcrumb: [
        {
          text: 'navigations.coupons',
          active: true,
        },
      ],
    },
  },
  {
    path: '/coupons/:id/edit',
    name: 'EditCoupon',
    component: () => import('@/views/Admin/Coupons/edit.vue'),
    meta: {
      pageTitle: 'coupon.update',
      breadcrumb: [
        {
          text: 'coupon.update',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-coupon',
    name: 'AddNewCoupon',
    component: () => import('@/views/Admin/Coupons/add.vue'),
    meta: {
      pageTitle: 'coupon.new',
      breadcrumb: [
        {
          text: 'coupon.new',
          active: true,
        },
      ],
    },
  },
  // Coupons End

  // Reviews Start
  {
    path: '/reviews',
    name: 'reviews',
    component: () => import('@/views/Admin/Reviews/list.vue'),
    meta: {
      pageTitle: 'navigations.reviews',
      breadcrumb: [
        {
          text: 'navigations.reviews',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reviews/:id/edit',
    name: 'EditReview',
    component: () => import('@/views/Admin/Reviews/edit.vue'),
    meta: {
      pageTitle: 'review.update',
      breadcrumb: [
        {
          text: 'review.update',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-review',
    name: 'AddNewReview',
    component: () => import('@/views/Admin/Reviews/add.vue'),
    meta: {
      pageTitle: 'review.new',
      breadcrumb: [
        {
          text: 'review.new',
          active: true,
        },
      ],
    },
  },
  // Reviews End

  // Emails
  {
    path: '/emails',
    name: 'Emails',
    component: () => import('@/views/Admin/Emails/list.vue'),
    meta: {
      pageTitle: 'navigations.emails',
      breadcrumb: [
        {
          text: 'navigations.emails',
          active: true,
        },
      ],
    },
  },
  {
    path: '/emails/:id',
    name: 'ViewEmails',
    component: () => import('@/views/Admin/Emails/detail.vue'),
    meta: {
      pageTitle: 'email.viewEmail',
      breadcrumb: [
        {
          text: 'email.viewEmail',
          active: true,
        },
      ],
    },
  },
  // Emails End

  // Email templates
  {
    path: '/email/templates',
    name: 'EmailTemplates',
    component: () => import('@/views/Admin/EmailTemplates/list.vue'),
    meta: {
      pageTitle: 'navigations.emailTemplates',
      breadcrumb: [
        {
          text: 'navigations.emailTemplates',
          active: true,
        },
      ],
    },
  },
  {
    path: '/email/templates/:id/edit',
    name: 'EditEmailTemplate',
    component: () => import('@/views/Admin/EmailTemplates/edit.vue'),
    meta: {
      pageTitle: 'email.emailTemplateUpdate',
      breadcrumb: [
        {
          text: 'email.emailTemplateUpdate',
          active: true,
        },
      ],
    },
  },
  // Email template end

  // Sms
  {
    path: '/sms',
    name: 'Sms',
    component: () => import('@/views/Admin/Sms/list.vue'),
    meta: {
      pageTitle: 'navigations.sms',
      breadcrumb: [
        {
          text: 'navigations.sms',
          active: true,
        },
      ],
    },
  },
  // Sms End

  // Sms templates
  {
    path: '/sms/templates',
    name: 'SmsTemplates',
    component: () => import('@/views/Admin/SmsTemplates/list.vue'),
    meta: {
      pageTitle: 'navigations.smsTemplates',
      breadcrumb: [
        {
          text: 'navigations.smsTemplates',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sms/templates/:id/edit',
    name: 'EditSmsTemplate',
    component: () => import('@/views/Admin/SmsTemplates/edit.vue'),
    meta: {
      pageTitle: 'sms.smsTemplateUpdate',
      breadcrumb: [
        {
          text: 'sms.smsTemplateUpdate',
          active: true,
        },
      ],
    },
  },
  // Sms template end

  // Invoices
  /* {
    path: '/invoices',
    name: 'Invoices',
    component: () => import('@/views/Admin/Invoices/list.vue'),
    meta: {
      pageTitle: 'navigations.invoices',
      breadcrumb: [
        {
          text: 'navigations.invoices',
          active: true,
        },
      ],
    },
  },
  {
    path: '/generate-invoice/:id',
    name: 'GenerateInvoice',
    component: () => import('@/views/Admin/Invoices/generate.vue'),
    meta: {
      layout: 'pdf'
    },
  }, */
  // Invoices End

  // Variables
  {
    path: '/variables',
    name: 'variables',
    component: () => import('@/views/Admin/Variables/list.vue'),
    meta: {
      pageTitle: 'navigations.variables',
      breadcrumb: [
        {
          text: 'navigations.variables',
          active: true,
        },
      ],
    },
  },
  {
    path: '/variables/:id/edit',
    name: 'EditVariable',
    component: () => import('@/views/Admin/Variables/edit.vue'),
    meta: {
      pageTitle: 'variable.update',
      breadcrumb: [
        {
          text: 'variable.update',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-variable',
    name: 'AddNewVariable',
    component: () => import('@/views/Admin/Variables/add.vue'),
    meta: {
      pageTitle: 'variable.new',
      breadcrumb: [
        {
          text: 'variable.new',
          active: true,
        },
      ],
    },
  },
  // Variables End

  // User profile
  {
    path: '/profile',
    name: 'EditProfile',
    component: () => import('@/views/Admin/UserProfile/edit.vue'),
    meta: {
      pageTitle: 'user.update',
      breadcrumb: [
        {
          text: 'user.update',
          active: true,
        },
      ],
    },
  },

  // Partner
  // Vouchers
  {
    path: '/vouchers',
    name: 'Vouchers',
    component: () => import('@/views/Partner/Vouchers/list.vue'),
    meta: {
      pageTitle: 'navigations.partnerVouchers',
      breadcrumb: [
        {
          text: 'navigations.partnerVouchers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/vouchers/:id/edit',
    name: 'EditVoucher',
    component: () => import('@/views/Partner/Vouchers/edit.vue'),
    meta: {
      pageTitle: 'voucher.update',
      breadcrumb: [
        {
          text: 'voucher.update',
          active: true,
        },
      ],
    },
  },
  /*{
    path: '/add-voucher',
    name: 'AddNewVoucher',
    component: () => import('@/views/Partner/Vouchers/add.vue'),
    meta: {
      pageTitle: 'voucher.new',
      breadcrumb: [
        {
          text: 'voucher.new',
          active: true,
        },
      ],
    },
  },*/
  {
    path: '/generate-voucher/:id',
    name: 'GenerateVoucher',
    component: () => import('@/views/Partner/Vouchers/generate.vue'),
    meta: {
      layout: 'pdf'
    },
  },
  // Vouchers End
  // Flights
  {
    path: '/flights',
    name: 'Flights',
    component: () => import('@/views/Partner/Flights/list.vue'),
    meta: {
      pageTitle: 'navigations.partnerFlights',
      breadcrumb: [
        {
          text: 'navigations.partnerFlights',
          active: true,
        },
      ],
    },
  },
  {
    path: '/flights/:id/edit',
    name: 'EditFlight',
    component: () => import('@/views/Partner/Flights/edit.vue'),
    meta: {
      pageTitle: 'flight.update',
      breadcrumb: [
        {
          text: 'flight.update',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-flight',
    name: 'AddNewFlight',
    component: () => import('@/views/Partner/Flights/add.vue'),
    meta: {
      pageTitle: 'flight.add',
      breadcrumb: [
        {
          text: 'flight.add',
          active: true,
        },
      ],
    },
  },
  // Flights End
  // Invoices
  {
    path: '/partner-invoices',
    name: 'PartnerInvoices',
    component: () => import('@/views/Partner/Invoices/list.vue'),
    meta: {
      pageTitle: 'navigations.invoices',
      breadcrumb: [
        {
          text: 'navigations.invoices',
          active: true,
        },
      ],
    },
  },
  {
    path: '/partner-invoices/:id/edit-invoice-address',
    name: 'EditPartnerInvoice',
    component: () => import('@/views/Partner/Invoices/edit.vue'),
    meta: {
      pageTitle: 'invoice.update',
      breadcrumb: [
        {
          text: 'invoice.update',
          active: true,
        },
      ],
    },
  },
  {
    path: '/partner-invoices/add-invoice-address',
    name: 'AddNewPartnerInvoice',
    component: () => import('@/views/Partner/Invoices/add.vue'),
    meta: {
      pageTitle: 'invoice.new',
      breadcrumb: [
        {
          text: 'invoice.new',
          active: true,
        },
      ],
    },
  },
  // Invoices End
  // Records
  {
    path: '/records',
    name: 'Records',
    component: () => import('@/views/Partner/Records/list.vue'),
    meta: {
      pageTitle: 'navigations.partnerRecords',
      breadcrumb: [
        {
          text: 'navigations.partnerRecords',
          active: true,
        },
      ],
    },
  },
  {
    path: '/records/send/:id?',
    name: 'SendRecord',
    component: () => import('@/views/Partner/Records/send.vue'),
    meta: {
      pageTitle: 'record.send',
      breadcrumb: [
        {
          text: 'record.send',
          active: true,
        },
      ],
    },
  },
  // Records End
  // Activation / Redeem Start
  {
    path: '/activation',
    name: 'activation',
    component: () => import('@/views/Admin/Activation/index.vue'),
    meta: {
      layout: 'full',
    },
  },
  // Activation / Redeem End
  // Locations
  {
    path: '/locations',
    name: 'locations',
    component: () => import('@/views/Admin/Locations/list.vue'),
    meta: {
      pageTitle: 'navigations.locations',
      breadcrumb: [
        {
          text: 'navigations.locations',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-location',
    name: 'AddNewLocation',
    component: () => import('@/views/Admin/Locations/add.vue'),
    meta: {
      pageTitle: 'location.new',
      breadcrumb: [
        {
          text: 'location.new',
          active: true,
        },
      ],
    },
  },
  {
    path: '/locations/:id/edit',
    name: 'EditLocation',
    component: () => import('@/views/Admin/Locations/edit.vue'),
    meta: {
      pageTitle: 'location.update',
      breadcrumb: [
        {
          text: 'location.update',
          active: true,
        },
      ],
    },
  },
  // Locations End
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (to.name === 'activation') {
    return next()
  }

  // Redirect to login if not logged in
  if (!isLoggedIn && (to.name !== 'login' && to.name !== 'forgot-password')) {
    return next({ name: 'login' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  // Router guards
  if (isLoggedIn) {
    const userData = getUserData()

    // Admin
    if (userData.role === 'ROLE_ADMIN' && (['Records', 'SendRecord', 'PartnerInvoices', 'EditPartnerInvoice', 'AddNewPartnerInvoice'].includes(to.name))) {
      next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }

    // Partner
    if(userData.role === 'ROLE_PARTNER') {
      let enabled = ['EditProfile', 'logout', 'login', 'home']
      if(userData.customFields.find(cf => cf.name === 'redeem_voucher' && cf.value === 'true')) {
        enabled = enabled.concat(['Vouchers', 'EditVoucher'])
      }
      if(userData.customFields.find(cf => cf.name === 'discounted_flights' && cf.value === 'true')) {
        enabled = enabled.concat(['Flights', 'EditFlight', 'AddNewFlight'])
      }
      if(userData.customFields.find(cf => cf.name === 'record' && cf.value === 'true')) {
        enabled = enabled.concat(['Records', 'SendRecord'])
      }
      if(userData.customFields.find(cf => cf.name === 'invoices' && cf.value === 'true')) {
        enabled = enabled.concat(['PartnerInvoices', 'EditPartnerInvoice', 'AddNewPartnerInvoice'])
      }
      if (!enabled.includes(to.name)) {
        next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
      }
    }
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
