<template>
  <div class="navbar-top">
    <a href="javascript:void(0)" class="navbar-toggler" @click="showNav">
      <bootstrap-icon
          icon="list"
          size="1x"
        />
    </a>
    <!-- User circle info -->
    <router-link v-if="user" to="/profile" class="user">
      <b-list-group>
        <b-list-group-item class="user-circle">
          <div v-if="user.role === 'ROLE_ADMIN'" class="user-info">
            {{ user.givenName }} {{ user.familyName }}
            <span>{{ $helper.getEnumTranslation("roles", user.role, $i18n.locale) }}</span>
          </div>
          <div v-else-if="user.role === 'ROLE_PARTNER'" class="user-info">
            {{ user.companyName }}
            <span>{{ $helper.getEnumTranslation("roles", user.role, $i18n.locale) }}</span>
          </div>
          <b-avatar
            badge
            class="user-photo"
            variant="primary"
            icon="star-fill"
            :src="user.fileUrl ? user.fileUrl : ''"
            badge-variant="success"
          />
        </b-list-group-item>
      </b-list-group>
    </router-link>
  </div>
</template>

<script>
import { getUserData } from "@/auth/utils";

export default {
  components: {},
  data() {
    return {
      user: getUserData(),
    }
  },
  methods: {
    showNav() {
      this.$emit('navToggle', true)
    }
  }
};
</script>
